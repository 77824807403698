import React, { useRef, useState } from "react";
import contact from "../assets/img/webp/contectus-img.webp";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

function Contact({ contactRef }) {
  const form = useRef();
  const [resistration, setresistration] = useState({
    name: "",
    lastname: "",
    phone: "",
    email: "",
    message: "",
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // const { name, value } = e.target;
    setresistration({ ...resistration, [name]: value });
  };
  const FormSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_x7qq9zo",
        "template_n9z8z4g",
        form.current,
        "D3xj5Ajl3IMydrZkw"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          swal({
            title: "Successfully Sent",
            text: "Thank you for the submission",
            icon: "success",
          });
        },
        function (error) {
          console.log("FAILED...", error);
          swal({
            title: "Error!",
            text: "Failed to submit form! Please Try again.",
            icon: "error",
          });
        }
      );
    console.log(resistration);
    setresistration({
      name: "",
      phone: "",
      email: "",
      message: "",
      lastname: "",
    });
  };
  return (
    <section ref={contactRef} id="contact" className="py-5">
      <div className="container">
        <div className="pb-lg-5 mb-xxl-5">
          {" "}
          <div className="row align-items-center">
            {" "}
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="  transition300">
                <div className="position-relative w_sm_75 w_xsm_100 w_lg_100 mx-auto position-relative orange_bg_box1 orange_bg_box2">
                  <img className="w-100 p-2 pe-lg-4 " src={contact} alt="img" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 mt-sm-5 mt-4 mt-lg-0"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="ps-lg-4">
                <h2 className="ff_jakarta fw-semibold fs_4x4l text_black ">
                  Contact Us
                </h2>
                <form ref={form} onSubmit={FormSubmit} className="mt-xl-5 mt-4">
                  <div className="d-flex gap-sm-3 gap-1">
                    <div className="d-flex flex-column w-50">
                      <label
                        className="ff_jakarta fw-normal fs_sm text_black"
                        for="fname"
                      >
                        First Name*
                      </label>
                      <input
                        required
                        className="mt-1 py-sm-3 py-1 px-2"
                        type="text"
                        value={resistration.name}
                        onChange={handleInput}
                        id="name"
                        name="name"
                      />
                    </div>
                    <div className="d-flex flex-column w-50">
                      <label
                        className="ff_jakarta fw-normal fs_sm text_black"
                        for="lname"
                      >
                        Last Name*
                      </label>
                      <input
                        required
                        className="mt-1 py-sm-3 py-1 px-2"
                        type="text"
                        value={resistration.lastname}
                        onChange={handleInput}
                        id="lastname"
                        name="lastname"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column mt-3">
                    <label
                      className="ff_jakarta fw-normal fs_sm text_black"
                      for="email"
                    >
                      Email
                    </label>
                    <input
                      required
                      className="mt-1 py-sm-3 py-1 px-2"
                      type="email"
                      value={resistration.email}
                      onChange={handleInput}
                      id="Email"
                      name="email"
                    />
                  </div>
                  <div className="d-flex flex-column mt-3">
                    <label
                      className="ff_jakarta fw-normal fs_sm text_black"
                      for="Company"
                    >
                      Company Name
                    </label>
                    <input
                      className="mt-1 py-sm-3 py-1 px-2"
                      type="text"
                      value={resistration.phone}
                      onChange={handleInput}
                      id="Phone"
                      name="phone"
                    />
                  </div>
                  <label
                    for="Massage"
                    className="ff_jakarta fw-normal fs_sm text_black mt-3"
                  >
                    Message
                  </label>
                  <textarea
                    style={{ resize: "none" }}
                    className="w-100 mt-1 px-2 pt-2"
                    value={resistration.message}
                    onChange={handleInput}
                    id="message"
                    name="message"
                  ></textarea>
                  <input
                    className="w-100 submit_btn ff_jakarta fw-normal fs_sm text-white"
                    type="submit"
                    value="submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
