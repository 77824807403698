import React, { useState, useEffect } from "react";

const BackTop = () => {
  const top = () => {
    document.documentElement.scrollTop = 0;
  };
  const [backToTop, setbackToTop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > "100px") {
        setbackToTop(true);
      } else {
        setbackToTop(false);
      }
    });
  }, []);
  return (
    <div>
      <button
        id="btn"
        onClick={() => top()}
        style={{ zIndex: "99" }}
        className={
          backToTop
            ? "btn d-block position-fixed   bg-danger_one  end-0 bottom-0 mb-2 border-0 "
            : "btn d-none position-fixed  end-0 bottom-0 mb-2 border-0"
        }
      >
        <span className=" d-flex  justify-content-center align-items-center btop_animation">
          <svg
            width="20"
            height="20"
            fill="white"
            className="bi bi-chevron-double-up bg-danger"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"
            ></path>
            <path
              fillRule="evenodd"
              d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
            ></path>
          </svg>
        </span>
      </button>
    </div>
  );
};
export default BackTop;
